import { isEmpty, map } from 'lodash'
import { MenuTabs } from 'components/pages/explore/stores/MenuTabs'
import * as Widget from 'components/Widget'
import { YourFavorites } from 'screens/my-joe/sections/YourFavorites'
import { RecentOrders } from 'screens/my-joe/sections/RecentOrders'
import { RecommendedItems } from 'screens/my-joe/sections/RecommendedItems'
import { FeaturedItems } from 'screens/my-joe/sections/FeaturedItems'
import { useMarkItemAsUsual } from 'hooks/cart/useMarkItemAsUsual'
import { useAuth } from 'hooks/useAuth'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { StoreMenuProps } from './StoreMenu.types'
import { StoreMenuPlaceholder } from './StoreMenuPlaceholder'
import { ScrollElement, widgetCSS } from './StoreMenu.styles'
import { StoreMenuCategory } from './StoreMenuCategory'

export const defaultMenuTabsOffset = -140

const Menu = ({
  storeId,
  categories = [],
  featuredItemsByCategoryIds = {},
  popularItems = [],
  hideFeaturedWidget = false,
  hideRecommendedWidget = false,
  isLoading = false,
  tabsCss,
  tabsOffset = defaultMenuTabsOffset, // Offset to account for sticky tabs and fixed header
}: StoreMenuProps) => {
  const { onClickUsualItemMark } = useMarkItemAsUsual()
  const isAuth = useAuth()
  const [hasFavorites, setHasFavorites] = useState(false)

  const lastCategoryStyles: CSSProperties = {
    minHeight: `calc(${tabsOffset}px - var(--joe-space-navHeight) + 100vh)`,
  }

  const verifyFavoritesTab = useCallback(
    (data: unknown[]) => {
      if (hasFavorites) {
        return
      }

      if (!isEmpty(data)) {
        setHasFavorites(true)
      }
    },

    [hasFavorites]
  )

  const categoryTabs = useMemo(() => {
    const tabs = map(categories, 'name')

    if (isEmpty(tabs)) {
      return []
    }

    if (isAuth && hasFavorites) {
      return ['Favorites', ...tabs]
    }

    return tabs
  }, [hasFavorites, isAuth, categories])

  return (
    <Widget.Composed css={widgetCSS} unstyled>
      <MenuTabs
        tabs={categoryTabs}
        offset={tabsOffset}
        isLoading={isLoading}
        css={tabsCss}
        isDynamic
      />

      {isAuth && (
        <ScrollElement name="Favorites">
          <YourFavorites
            storeId={storeId}
            onClickUsualItemMark={onClickUsualItemMark}
            onFetchFavorites={verifyFavoritesTab}
          />
          <RecentOrders
            storeId={storeId}
            onClickUsualItemMark={onClickUsualItemMark}
            onFetchRecentOrders={verifyFavoritesTab}
          />
          {!hideRecommendedWidget && (
            <RecommendedItems
              storeId={storeId}
              onFetchRecommendedItems={verifyFavoritesTab}
            />
          )}
          {!hideFeaturedWidget && (
            <FeaturedItems
              storeId={storeId}
              onFetchFeaturedItems={verifyFavoritesTab}
            />
          )}
        </ScrollElement>
      )}

      {isLoading ? (
        <StoreMenuPlaceholder />
      ) : (
        categories.map((category, index) => (
          <ScrollElement
            id={category.id}
            key={category.name}
            name={category.name}
            style={index === categories.length - 1 ? lastCategoryStyles : {}}
          >
            <StoreMenuCategory
              category={category}
              featuredItemIds={map(
                featuredItemsByCategoryIds[category.id],
                'itemId'
              )}
              popularItems={popularItems}
              storeId={storeId}
            />
          </ScrollElement>
        ))
      )}
    </Widget.Composed>
  )
}

export default Menu
