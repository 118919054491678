import Link from 'next/link'
import { CSS } from '@stitches/react'

/* Components */
import * as Icon from 'components/DesignSystemIcons'
import OrderCardDetails from './OrderCardDetails'
import { Button } from 'design-system/buttons'

/* Config */
import { styled } from 'styles/stitches.config'

import useOrderAgain from 'hooks/useOrderAgain'
import { OrderMyJoe, OrderMyJoeItem } from 'types/api'
import { OrderCardItem } from './OrderCardItem'
import { useMemo } from 'react'
import { getRecentOrderItemHash } from 'utils/order'

const Anchor = styled('a', {
  display: 'block',
  padding: '$m 0',
  borderRadius: '$s',
  backgroundColor: '$white',
  margin: '$xxl $s',
  boxShadow: '$feed',
})

const OrderAgainSection = styled('div', {
  alignItems: 'center',
  color: '$grey800',
  display: 'flex',
  fontSize: '$body2',
  justifyContent: 'space-between',
  padding: '0 $s $s',
  lineHeight: '1em',
})

const Points = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const PointsText = styled('span', {
  fontSize: '$body2',
  lineHeight: '$body2',
  color: '$green800',
  fontWeight: '$bold',
})

const ItemSection = styled('div', {
  margin: '$m $s 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '$s',
})

const orderAgainButtonStylings = {
  width: 'fit-content',
  fontSize: '$small',
  padding: '0 $s',
  height: '40px',
}

type MyJoeOrderCardProps = {
  order: OrderMyJoe
  css?: CSS
  onClickUsualItemMark: (item: OrderMyJoeItem, storeId: string) => void
}

const MyJoeOrderCard = ({
  order,
  css,
  onClickUsualItemMark,
}: MyJoeOrderCardProps) => {
  const usualItemHashmap = useMemo(() => {
    return order.items.reduce((hashmap, item) => {
      // istanbul ignore next
      if (item.usualItemId) {
        hashmap.set(
          getRecentOrderItemHash(item, order.storeId),
          item.usualItemId
        )
      }

      return hashmap
    }, new Map())
  }, [order.items, order.storeId])

  const { handleOnClickOrderAgain: orderAgain, loading } = useOrderAgain(
    order.orderId,
    usualItemHashmap
  )

  // istanbul ignore next
  const handleOnClickOrderAgain = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    orderAgain()
  }

  // istanbul ignore next
  return (
    <Link
      href={{
        pathname: '/account/orders/[orderId]',
        query: { orderId: order.orderId },
      }}
      passHref
      prefetch={false}
    >
      <Anchor aria-label="order-card">
        <OrderCardDetails
          css={css}
          imageSize={18}
          orderIssuedAt={order.ts}
          storeName={order.storeName}
          storePhoto={order.storePhoto ?? ''}
          hasImage
          bigHeader
        />
        <OrderAgainSection>
          <Points>
            <Icon.Inliner css={{ color: '$yellow500', marginRight: '$xxxs' }}>
              <Icon.SpecialStar size={24} />
            </Icon.Inliner>
            <PointsText>+{order.point} pts</PointsText>
          </Points>

          {!!order.orderAgain && (
            <Button
              css={{
                ...orderAgainButtonStylings,
              }}
              cta
              disabled={loading}
              onClick={handleOnClickOrderAgain}
              size="small"
              variant="secondary"
            >
              Order again
            </Button>
          )}
        </OrderAgainSection>
        {!!order.items.length && (
          <ItemSection>
            {order.items.map((item, index) => (
              <OrderCardItem
                item={item}
                key={`${item.id}${index}`}
                onClickUsualItemMark={() =>
                  onClickUsualItemMark(item, order.storeId)
                }
              />
            ))}
          </ItemSection>
        )}
      </Anchor>
    </Link>
  )
}

export default MyJoeOrderCard
