import { Bone, Skeleton } from 'components/SkeletonKit'
import { Box } from 'design-system/box'
import { map, range } from 'lodash'
import { styled } from 'styles/stitches.config'

const MenuItemBox = styled(Box, {
  variants: {
    last: {
      true: {
        borderBottom: 'none',
      },
      false: {
        borderBottom: '1px solid $grey200',
      },
    },
  },
  defaultVariants: {
    last: false,
  },
})

export const MenuItemPlaceholder = ({ last = false }: { last?: boolean }) => (
  <MenuItemBox
    flex
    alignItems="center"
    css={{ padding: '$m $s', gap: '$xs' }}
    last={last}
  >
    <Box flex css={{ flex: 1, gap: '$xxxs', flexDirection: 'column' }}>
      <Bone variant="body1" css={{ width: '60%', margin: '0' }} />
      <Bone variant="body3" css={{ margin: '0', width: '90%' }} />
      <Bone variant="body1" css={{ width: '30%', margin: '0' }} />
    </Box>
    <Bone css={{ width: 92, height: 92, margin: '0' }} />
  </MenuItemBox>
)

export const MenuCategoryPlaceholder = ({
  totalItems = 3,
}: {
  totalItems?: number
}) => (
  <Box css={{ padding: '$m 0' }}>
    <Bone
      variant="heading3"
      css={{ borderRadius: '$full', marginBottom: '$xxs' }}
    />
    <div>
      {map(range(totalItems), (index) => (
        <MenuItemPlaceholder key={index} last={index === totalItems - 1} />
      ))}
    </div>
  </Box>
)

export const StoreMenuPlaceholder = () => (
  <Skeleton
    data-testid="store-menu-placeholder"
    css={{ padding: '$l $s', gap: '$s' }}
  >
    <MenuCategoryPlaceholder />
    <MenuCategoryPlaceholder />
  </Skeleton>
)
