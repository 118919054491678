import { StoreMenuCategoryProps } from './StoreMenu.types'
import {
  StoreMenuFeaturedItems,
  StoreMenuSubcategory,
} from './StoreMenuSubCategory'
import { MenuCategoryPlaceholder } from './StoreMenuPlaceholder'
import { useLazyCategory } from 'hooks/useLazyCategory'
import { chunk, isEmpty, map, range } from 'lodash'
import { Box } from 'design-system/box'

export const StoreMenuCategory = ({
  category: { id: categoryId, itemsCount, sectionsCount },
  featuredItemIds,
  popularItems,
  storeId,
}: StoreMenuCategoryProps) => {
  const { ref, category, isLoading } = useLazyCategory(storeId, categoryId)

  const totalSections = isEmpty(featuredItemIds)
    ? sectionsCount
    : sectionsCount + 1

  const totalItems = itemsCount + featuredItemIds.length

  const sectionsSkeletons = chunk(
    range(totalItems),
    Math.ceil(totalItems / totalSections)
  )

  return (
    <div ref={ref}>
      {isLoading || !category ? (
        <Box css={{ paddingBottom: '20px' }}>
          {map(sectionsSkeletons, (section, index) => (
            <MenuCategoryPlaceholder key={index} totalItems={section.length} />
          ))}
        </Box>
      ) : (
        <>
          <StoreMenuFeaturedItems
            category={category}
            featuredItemIds={featuredItemIds}
            popularItems={popularItems}
            storeId={storeId}
          />
          {category.subCategories.map((subcategory) => (
            <StoreMenuSubcategory
              subcategory={subcategory}
              popularItems={popularItems}
              storeId={storeId}
              key={`${category.name}-${subcategory.name}`}
            />
          ))}
        </>
      )}
    </div>
  )
}
