import { find } from 'lodash'
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts'
import { useSWR } from './useSWR'

/**
 * Custom hook to lazily load a category based on intersection observer and debounced value.
 *
 * @param {string} storeId - The ID of the store.
 * @param {string} categoryId - The ID of the category to be fetched.
 * @returns - An object containing:
 *   - `ref` (React.RefObject): A ref to be attached to the element to observe.
 *   - `category` (StoreCategory | undefined): The fetched category data.
 *   - `isLoading` (boolean): A boolean indicating if the data is currently being loaded.
 *   - `error` (Error | undefined): An error object if an error occurred during fetching.
 */
export const useLazyCategory = (storeId: string, categoryId: string) => {
  const { isIntersecting, ref, entry } = useIntersectionObserver({
    freezeOnceVisible: true,
    rootMargin: '-200px 0px 200px 0px',
  })

  const [debouncedIsIntersecting] = useDebounceValue(isIntersecting, 300)

  const { data, isLoading, error } = useSWR(
    'getMenuCategoryById',
    { storeId, categoryId },
    { shouldFetch: debouncedIsIntersecting }
  )

  const category = find(data?.data.menu, { id: categoryId })

  return { ref, category, isLoading, error, entry }
}
